<template>
  <div>
    <div class="news">
      <div class="title">
        Dr. Shirley Liu of GV20 Therapeutics is inducted into the AIMBE College of Fellows
      </div>
      <div class="time">
        Feb 25, 2022
      </div>
      <div class="content">
        <div>
          <p>
            <img width="100%" src="../assets/news/news4.jpg" alt="">
          </p>
        </div>
        <p>
          CAMBRIDGE, MA., Feb. 19, 2022 – GV20 Therapeutics is delighted to announce that its co-founder and Chief Executive Officer, Dr. Xiaole Shirley Liu, has been selected by the American Institute for Medical and Biological Engineering (AIMBE) to its 2022 College of Fellows.
        </p>
        <p>
          Dr. Liu was elected by peers and members of the AIMBE College of Fellows for outstanding contributions to computational biology research and education in transcriptional and epigenetic gene regulation and translational cancer medicine. The College of Fellows is comprised of the top two percent of medical and biological engineers in the country. The most accomplished and distinguished engineering and medical school chairs, research directors, professors, innovators, and successful entrepreneurs comprise the College of Fellows. AIMBE Fellows are regularly recognized for their contributions in teaching, research, and innovation. AIMBE Fellows have been awarded the Nobel Prize, the Presidential Medal of Science and the Presidential Medal of Technology and Innovation, and many also are members of the National Academy of Engineering, National Academy of Medicine, and the National Academy of Sciences.
        </p>
        <p>
          A formal induction ceremony will be held during AIMBE's 2022 Annual Event in March. This prestigious honor is also a testimony of the innovation behind GV20's novel genomic and AI platforms built upon Dr. Liu's expertise in computational biology and translational research. GV20 congratulates Dr. Liu for this significant achievement and looks forward to advancing its exciting discovery programs from pre-clinical to early-stage clinical evaluation under her leadership.
        </p>
        <div class="item">
          <div class="font-weight-bold">
            About Dr. Liu
          </div>
          <p>
            Dr. Xiaole Shirley Liu co-founded GV20 Therapeutics in 2016 and became the CEO of GV20 in 2022. She was a Professor of Biostatistics and Computational Biology at Dana-Farber Cancer Institute and Harvard T.H. Chan School of Public Health and co-director of the Center for Functional Cancer Epigenetics at Dana-Farber Cancer Institute. Her computational biology work refined our understanding of hormone receptor therapies, epigenetic inhibitors, gamma-secretase inhibitors, receptor tyrosine kinase inhibitors, and immune checkpoint inhibitors in different cancers. Dr. Liu is a highly cited researcher with a prodigious publication record that includes more than 200 papers published by her group, many in high-profile journals and highly cited. Shirley received the Sloan Research Fellowship (2008), has been a Breast Cancer Research Foundation Investigator (2017) and became a Fellow of ISCB (2019). In addition, she was recognized with the Benjamin Franklin Award for Open Access in the Life Sciences and the ISCB Innovator Award in 2020.
          </p>
        </div>
        <div class="item">
          <div class="font-weight-bold">
            About GV20 Therapeutics
          </div>
          <p>
            GV20 Therapeutics (<a href="http://gv20therapeutics.com/">http://gv20therapeutics.com/</a>) was incorporated in 2016 with sites in Cambridge, USA, and Shanghai, China. The company utilizes high throughput functional genomics and AI approaches to identify novel cancer immunology drug targets, and discover and develop effective antibody drugs in cancer. GV20's proprietary antibody drug discovery engine has helped establish a solid portfolio of immuno-oncology antibody drug candidates for cancer treatment.
          </p>
        </div>
      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {

    }
  },
  components: {
    Footers
  }
}
</script>
<style scoped lang="scss">
.news {
  font-size: 30px;
  font-weight: 500;
  padding: 0 40px;
  .title {
    font-weight: bold;
    font-size: 40px;
    padding-top: 40px;
  }
  .time {
    font-size: 20px;
    color: #666666;
    font-style: italic;
    margin: 50px 0;
  }
  .item {
    .font-weight-bold {
      margin-bottom: 30px;
    }
  }
}
</style>

